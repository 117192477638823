.project {
  all: initial; /* Don't inherit any styling when in the Viz Workbench */

  position: relative;
  height: 100%;
  font-size: 16px;
}

/* Cribbed from santize.css */

/* Document
 * ========================================================================== */

/**
 * Add border box sizing in all browsers (opinionated).
 */

.project *,
.project ::before,
.project ::after {
  box-sizing: border-box;
}

/**
 * 1. Add text decoration inheritance in all browsers (opinionated).
 * 2. Add vertical alignment inheritance in all browsers (opinionated).
 */

.project ::before,
.project ::after {
  text-decoration: inherit; /* 1 */
  vertical-align: inherit; /* 2 */
}

/**
 * 1. Use the default cursor in all browsers (opinionated).
 * 2. Change the line height in all browsers (opinionated).
 * 3. Use a 4-space tab width in all browsers (opinionated).
 * 4. Remove the grey highlight on links in iOS (opinionated).
 * 5. Prevent adjustments of font size after orientation changes in
 *    IE on Windows Phone and in iOS.
 * 6. Breaks words to prevent overflow in all browsers (opinionated).
 */

.project {
  cursor: default; /* 1 */
  line-height: 1.25 /*1.5*/; /* 2 */
  -moz-tab-size: 4; /* 3 */
  tab-size: 4; /* 3 */
  -webkit-tap-highlight-color: transparent /* 4 */;
  -ms-text-size-adjust: 100%; /* 5 */
  -webkit-text-size-adjust: 100%; /* 5 */
  word-break: break-word; /* 6 */
}

/* Sections
 * ========================================================================== */

/**
 * Remove the margin in all browsers (opinionated).
 */

.project {
  /* body */
  margin: 0;
}

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Edge, Firefox, and Safari.
 */

.project h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content
 * ========================================================================== */

/**
 * Remove the margin on nested lists in Chrome, Edge, IE, and Safari.
 */

.project dl dl,
.project dl ol,
.project dl ul,
.project ol dl,
.project ul dl {
  margin: 0;
}

/**
  * Remove the margin on nested lists in Edge 18- and IE.
  */

.project ol ol,
.project ol ul,
.project ul ol,
.project ul ul {
  margin: 0;
}

/**
  * 1. Add the correct sizing in Firefox.
  * 2. Show the overflow in Edge 18- and IE.
  */

.project hr {
  height: 0; /* 1 */
  overflow: visible; /* 2 */
}

/**
  * Add the correct display in IE.
  */

.project main {
  display: block;
}

/**
  * Remove the list style on navigation lists in all browsers (opinionated).
  */

.project nav ol,
.project nav ul {
  list-style: none;
  padding: 0;
}

/**
  * 1. Correct the inheritance and scaling of font size in all browsers.
  * 2. Correct the odd `em` font sizing in all browsers.
  */

.project pre {
  /* Handled below font-family: monospace, monospace; */ /* 1 */
  font-size: 1em; /* 2 */
}

/* Text-level semantics
 * ========================================================================== */

/**
 * Remove the gray background on active links in IE 10.
 */

.project a {
  background-color: transparent;
}

/**
 * Add the correct text decoration in Edge 18-, IE, and Safari.
 */

.project abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted;
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */

.project b,
.project strong {
  font-weight: bolder;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */

.project code,
.project kbd,
.project samp {
  /* Handled below font-family: monospace, monospace; */ /* 1 */
  font-size: 1em; /* 2 */
}

/**
 * Add the correct font size in all browsers.
 */

.project small {
  font-size: 80%;
}

/* Embedded content
 * ========================================================================== */

/*
 * Change the alignment on media elements in all browsers (opinionated).
 */

.project audio,
.project canvas,
.project iframe,
.project img,
.project svg,
.project video {
  vertical-align: middle;
}

/**
  * Add the correct display in IE 9-.
  */

.project audio,
.project video {
  display: inline-block;
}

/**
  * Add the correct display in iOS 4-7.
  */

.project audio:not([controls]) {
  display: none;
  height: 0;
}

/**
  * Remove the border on iframes in all browsers (opinionated).
  */

.project iframe {
  border-style: none;
}

/**
  * Remove the border on images within links in IE 10-.
  */

.project img {
  border-style: none;
}

/**
  * Change the fill color to match the text color in all browsers (opinionated).
  */

.project svg:not([fill]) {
  fill: currentColor;
}

/**
  * Hide the overflow in IE.
  */

.project svg:not(:root) {
  overflow: hidden;
}

/* Forms
 * ========================================================================== */

/**
 * Remove the margin on controls in Safari.
 */

.project button,
.project input,
.project select {
  margin: 0;
}

/**
  * 1. Show the overflow in IE.
  * 2. Remove the inheritance of text transform in Edge 18-, Firefox, and IE.
  */

.project button {
  overflow: visible; /* 1 */
  text-transform: none; /* 2 */
}

/**
  * Correct the inability to style buttons in iOS and Safari.
  */

.project button,
.project [type='button'],
.project [type='reset'],
.project [type='submit'] {
  -webkit-appearance: button;
}

/**
  * 1. Change the inconsistent appearance in all browsers (opinionated).
  * 2. Correct the padding in Firefox.
  */

.project fieldset {
  border: 1px solid #a0a0a0; /* 1 */
  padding: 0.35em 0.75em 0.625em; /* 2 */
}

/**
  * Show the overflow in Edge 18- and IE.
  */

.project input {
  overflow: visible;
}

/**
  * 1. Correct the text wrapping in Edge 18- and IE.
  * 2. Correct the color inheritance from `fieldset` elements in IE.
  */

.project legend {
  color: inherit; /* 2 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  white-space: normal; /* 1 */
}

/**
  * 1. Add the correct display in Edge 18- and IE.
  * 2. Add the correct vertical alignment in Chrome, Edge, and Firefox.
  */

.project progress {
  display: inline-block; /* 1 */
  vertical-align: baseline; /* 2 */
}

/**
  * Remove the inheritance of text transform in Firefox.
  */

.project select {
  text-transform: none;
}

/**
  * 1. Remove the margin in Firefox and Safari.
  * 2. Remove the default vertical scrollbar in IE.
  * 3. Change the resize direction in all browsers (opinionated).
  */

.project textarea {
  margin: 0; /* 1 */
  overflow: auto; /* 2 */
  resize: vertical; /* 3 */
}

/**
  * Remove the padding in IE 10-.
  */

.project [type='checkbox'],
.project [type='radio'] {
  padding: 0;
}

/**
  * 1. Correct the odd appearance in Chrome, Edge, and Safari.
  * 2. Correct the outline style in Safari.
  */

.project [type='search'] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}

/**
  * Correct the cursor style of increment and decrement buttons in Safari.
  */

.project ::-webkit-inner-spin-button,
.project ::-webkit-outer-spin-button {
  height: auto;
}

/**
  * Correct the text style of placeholders in Chrome, Edge, and Safari.
  */

.project ::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}

/**
  * Remove the inner padding in Chrome, Edge, and Safari on macOS.
  */

.project ::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
  * 1. Correct the inability to style upload buttons in iOS and Safari.
  * 2. Change font properties to `inherit` in Safari.
  */

.project ::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}

/**
  * Remove the inner border and padding of focus outlines in Firefox.
  */

.project ::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
  * Restore the focus outline styles unset by the previous rule in Firefox.
  */

.project :-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
  * Remove the additional :invalid styles in Firefox.
  */

.project :-moz-ui-invalid {
  box-shadow: none;
}

/* Interactive
  * ========================================================================== */

/*
  * Add the correct display in Edge 18- and IE.
  */

.project details {
  display: block;
}

/*
  * Add the correct styles in Edge 18-, IE, and Safari.
  */

.project dialog {
  background-color: white;
  border: solid;
  color: black;
  display: block;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  left: 0;
  margin: auto;
  padding: 1em;
  position: absolute;
  right: 0;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
}

.project dialog:not([open]) {
  display: none;
}

/*
  * Add the correct display in all browsers.
  */

.project summary {
  display: list-item;
}

/* Scripting
  * ========================================================================== */

/**
  * Add the correct display in IE 9-.
  */

.project canvas {
  display: inline-block;
}

/**
  * Add the correct display in IE.
  */

.project template {
  display: none;
}

/* User interaction
  * ========================================================================== */

/*
  * 1. Remove the tapping delay in IE 10.
  * 2. Remove the tapping delay on clickable elements
       in all browsers (opinionated).
  */

.project a,
.project area,
.project button,
.project input,
.project label,
.project select,
.project summary,
.project textarea,
.project [tabindex] {
  -ms-touch-action: manipulation; /* 1 */
  touch-action: manipulation; /* 2 */
}

/**
  * Add the correct display in IE 10-.
  */

.project [hidden] {
  display: none;
}

/* Accessibility
  * ========================================================================== */

/**
  * Change the cursor on busy elements in all browsers (opinionated).
  */

.project [aria-busy='true'] {
  cursor: progress;
}

/*
  * Change the cursor on control elements in all browsers (opinionated).
  */

.project [aria-controls] {
  cursor: pointer;
}

/*
  * Change the cursor on disabled, not-editable, or otherwise
  * inoperable elements in all browsers (opinionated).
  */

.project [aria-disabled='true'],
.project [disabled] {
  cursor: not-allowed;
}

/*
  * Change the display on visually hidden accessible elements
  * in all browsers (opinionated).
  */

.project [aria-hidden='false'][hidden] {
  display: initial;
}

.project [aria-hidden='false'][hidden]:not(:focus) {
  clip: rect(0, 0, 0, 0);
  position: absolute;
}

/* Cribbed from sanitize.css forms.css */

/**
 * 1. Change the inconsistent appearance in all browsers (opinionated).
 * 2. Add typography inheritance in all browsers (opinionated).
 */

.project button,
.project input,
.project select,
.project textarea {
  background-color: transparent; /* 1 */
  border: 1px solid WindowFrame; /* 1 */
  color: inherit; /* 1 */
  font: inherit; /* 2 */
  letter-spacing: inherit; /* 2 */
  padding: 0.25em 0.375em; /* 1 */
}

/**
  * Change the inconsistent appearance in all browsers (opinionated).
  */

.project select {
  -moz-appearance: none;
  -webkit-appearance: none;
  background: no-repeat right center / 1em;
  border-radius: 0;
  padding-right: 1em;
}

/**
  * Change the inconsistent appearance in all browsers (opinionated).
  */

.project select:not([multiple]):not([size]) {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='4'%3E%3Cpath d='M4 0h6L7 4'/%3E%3C/svg%3E");
}

/**
  * Change the inconsistent appearance in IE (opinionated).
  */

.project ::-ms-expand {
  display: none;
}

/**
  * Correct the inconsistent appearance in IE (opinionated).
  */

.project :-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.54);
}

/* Cribbed from sanitize.css typography.css */

/**
 * Use the default user interface font in all browsers (opinionated).
 */
.project {
  font-family: system-ui, /* macOS 10.11-10.12 */ -apple-system, /* Windows 6+ */ 'Segoe UI',
    /* Android 4+ */ 'Roboto', /* Ubuntu 10.10+ */ 'Ubuntu', /* Gnome 3+ */ 'Cantarell',
    /* KDE Plasma 5+ */ 'Noto Sans', /* fallback */ sans-serif,
    /* macOS emoji */ 'Apple Color Emoji', /* Windows emoji */ 'Segoe UI Emoji',
    /* Windows emoji */ 'Segoe UI Symbol', /* Linux emoji */ 'Noto Color Emoji';
}

/**
 * Use the default monospace user interface font in all browsers (opinionated).
 */
.project code,
.project kbd,
.project samp,
.project pre {
  font-family:
    /* macOS 10.10+ */ 'Menlo', /* Windows 6+ */ 'Consolas',
    /* Android 4+ */ 'Roboto Mono', /* Ubuntu 10.10+ */ 'Ubuntu Monospace',
    /* KDE Plasma 5+ */ 'Noto Mono', /* KDE Plasma 4+ */ 'Oxygen Mono',
    /* Linux/OpenOffice fallback */ 'Liberation Mono', /* fallback */ monospace,
    /* macOS emoji */ 'Apple Color Emoji', /* Windows emoji */ 'Segoe UI Emoji',
    /* Windows emoji */ 'Segoe UI Symbol', /* Linux emoji */ 'Noto Color Emoji';
}
